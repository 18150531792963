<template>
    <div class="config-property">
        <label class="input-radio" v-if="!!value.APP_URL">
            <strong>Link para aplicativo B2B (deixe em branco se não deseja utilizar)</strong>
            <input type="text" v-model="value.APP_URL" class="form-control">
        </label>
    </div>
</template>

<script>
  export default {
    name: "APP_URL",
    props: [
      'value'
    ],
  }
</script>